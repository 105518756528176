<template>
	<div class='question' :class="{'phone' : isPhone}" :style="'width:' + width + 'px;'">
		<!-- select -->
		<div class='select' v-if='type == "select"'>
			<div class='no'>
				<span class='isMust' v-if='isMust'>*</span>
				<span class='n'>{{no}}.</span>
			</div>
			<div class='container'>
				<div class='title'>{{title}}</div>
				<div v-if='direction' class='direction'>{{direction}}</div>
				<a-select default-value="请选择" style="width: 320px;" @change="selectChange" v-model='value'
					:disabled='disabled'>
					<a-select-option :value="idx" v-for='(item,idx) of options' :key='idx' :title='item.label'>
						{{item.label || '请输入'}}
					</a-select-option>
				</a-select>
			</div>
		</div>


		<!-- input -->
		<div class='input' v-if='type == "input"'>
			<div class='no'>
				<span class='isMust' v-if='isMust'>*</span>
				<span class='n'>{{no}}.</span>
			</div>
			<div class='container'>
				<div class='title'>{{title}}</div>
				<div v-if='direction' class='direction'>{{direction}}</div>
				<a-input type="text" style="width: 320px;" placeholder="请输入" v-model='value' :disabled='disabled' />
			</div>
		</div>

		<!-- textarea -->
		<div class='textarea' v-if='type == "textarea"'>
			<div class='no'>
				<span class='isMust' v-if='isMust'>*</span>
				<span class='n'>{{no}}.</span>
			</div>
			<div class='container'>
				<div class='title'>{{title}}</div>
				<div v-if='direction' class='direction'>{{direction}}</div>
				<a-textarea placeholder="请输入" style="width: 320px;" v-model='value' :disabled='disabled' />
			</div>
		</div>

		<!-- radio -->
		<div class='radio' v-if='type == "radio"'>
			<div class='no'>
				<span class='isMust' v-if='isMust'>*</span>
				<span class='n'>{{no}}.</span>
			</div>
			<div class='container'>
				<div class='title'>{{title}}</div>
				<div v-if='direction' class='direction'>{{direction}}</div>
				<div class=radioGroup>
					<a-radio-group @change="radioChange" :default-value="val">
						<div v-for='(item,idx) of options' :key='idx' class='radioItem'>
							<a-radio :value="idx" :class='{img: item.img}' :disabled='disabled'>
								<div class='radioCon' v-if='item.img'>
									<div class="imgCon" @mouseenter="showDel = true" @mouseleave="showDel = false">
										<img class="image" :src='item.img' />
										<div class="del" v-if="isEdit && showDel">
											<a-icon @click.stop='item.img=""' style='color:#fff;font-size: 20px;' type="delete" />
										</div>
									</div>
									<span>
										{{item.label || '请输入最多20字符'}}
									</span>
									<div class='input' v-if='item.checkedFlag || item.edit'>
										<span class='red' v-if='item.isFill && item.isMust'>*</span>
										<a-input style="width: 300px;" :disabled='disabled' v-if="item.isFill" type="text" placeholder="请输入"
											v-model='item.answerBlank' />
									</div>
								</div>
								<div class='radioCon2' v-if='!item.img'>
									<span>
										{{item.label || '请输入最多20字符'}}
									</span>
									<div class='input' v-if='item.checkedFlag || item.edit'>
										<span class='red' v-if='item.isFill && item.isMust'>*</span>
										<a-input style="width: 300px;" v-if="item.isFill" type="text" :disabled='disabled' placeholder="请输入"
											v-model='item.answerBlank' />
									</div>
								</div>
							</a-radio>
						</div>
					</a-radio-group>
				</div>
			</div>
		</div>

		<!-- checkbox -->
		<div class='checkbox' v-if='type == "checkbox"'>
			<div class='no'>
				<span class='isMust' v-if='isMust'>*</span>
				<span class='n'>{{no}}.</span>
			</div>
			<div class='container'>
				<div class='title'>
					{{title}}
					<span v-if="min > 0 && min != 99999">(至少选{{min}}项{{max > 0 ?'' :')'}},</span>
					<span v-if="max > 0 && max != 99999">{{min > 0 ?' ' :'('}}至多选{{max}}项)</span>
				</div>
				<div v-if='direction' class='direction'>{{direction}}</div>
				<div class='checkboGroup'>
					<div v-for='(item,idx) of options' :key='idx' class="checkboxItem">
						<a-checkbox @change="checkboxChange(idx)" :class='{img: item.img}' :disabled='disabled'
							:checked='item.checkedFlag'>
							<div class='checkboxCon' v-if='item.img'>
								<div class="imgCon" @mouseenter="showDel = true" @mouseleave="showDel = false">
									<img class="image" :src='item.img' />
									<div class="del" v-if="isEdit && showDel">
										<a-icon @click.stop='item.img=""' style='color:#fff;font-size: 20px;' type="delete" />
									</div>
								</div>
								<span>
									{{item.label || '请输入最多20字符'}}
								</span>
								<div class='input' v-if='item.checkedFlag || item.edit'>
									<span class='red' v-if='item.isFill && item.isMust'>*</span>
									<a-input style="width: 300px;" :disabled='disabled' v-if="item.isFill" type="text" placeholder="请输入"
										v-model='item.answerBlank' />
								</div>
							</div>
							<div class='checkboxCon2' v-if='!item.img'>
								<span>
									{{item.label || '请输入最多20字符'}}
								</span>
								<div class='input' v-if='item.checkedFlag || item.edit'>
									<span class='red' v-if='item.isFill && item.isMust'>*</span>
									<a-input style="width: 300px;" :disabled='disabled' v-if="item.isFill" type="text" placeholder="请输入"
										v-model='item.answerBlank' />
								</div>
							</div>
						</a-checkbox>
					</div>
				</div>
			</div>
		</div>

		<!-- rate -->
		<div class='rate' v-if='type == "rate"'>
			<div class='no'>
				<span class='isMust' v-if='isMust'>*</span>
				<span class='n'>{{no}}.</span>
			</div>
			<div class='container'>
				<div class='title'>{{title}}</div>
				<div v-if='direction' class='direction'>{{direction}}</div>
				<div class='score' v-if="rateStyle != 'num'">
					<span class='minText'>{{options[0].label}}</span>
					<a-rate style='margin-right: 0.625rem;' :count='options.length' :disabled='disabled'
						@change='rateChange' v-model='value'>
						<a-icon slot="character" :type="rateStyle" theme='filled' />
					</a-rate>
					<span class='maxText' style="margin-left: 0;">{{options[options.length - 1].label}}</span>
				</div>
				<div class='scoreCon' v-if="rateStyle == 'num'">
					<span class='minText'>{{options[0].label}}</span>
					<div class='rate' :style='activeScoreStyle' v-if="!disabled">
						<div v-for='(item,idx) of options' :key='idx' @click='scoreClick(idx,item)'>{{item.score}}</div>
					</div>
					<div class='rate' :style='activeScoreStyle' v-if="disabled">
						<div v-for='(item,idx) of options' :key='idx'>{{item.score}}</div>
					</div>
					<span class='maxText' style="margin-left: 0;">{{options[options.length - 1].label}}</span>
				</div>
			</div>
		</div>
		<!-- score -->
		<!-- <div class='score' v-if='type == "score"'>
			<div class='no'>
				<span class='isMust' v-if='isMust'>*</span>
				<span class='n'>{{no}}.</span>
			</div>
			<div class='container'>
				<div class='title'>{{title}}</div>
				<div v-if='direction' class='direction'>{{direction}}</div>
				<div class='scoreCon'>
					<span class='minText'>{{minText}}</span>
					<div class='rate' :style='activeScoreStyle'>
						<div v-for='(item,idx) of 10' :key='idx' @click='scoreClick(idx,item)'>{{item}}</div>
					</div>
					<span class='maxText'>{{maxText}}</span>
				</div>
			</div>
		</div> -->
		<!-- slider -->
		<div class='slider' v-if='type == "slider"'>
			<div class='no'>
				<span class='isMust' v-if='isMust'>*</span>
				<span class='n'>{{no}}.</span>
			</div>
			<div class='container'>
				<div class='title'>{{title}}</div>
				<div v-if='direction' class='direction'>{{direction}}</div>
				<div class='slideCon'>
					<div class='text'>
						<span class='minText'>{{minText}}({{min}})</span>
						<span class='maxText'>{{maxText}}({{max}})</span>
					</div>
					<a-slider :min='min' :max='max' v-model="sliderValue" :disabled='disabled' />
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	/**
	 * @Author: Jenos
	 * @Date: 2021-09-17 15:08
	 * @LastEditTime: 2021-09-17 15:08
	 * @Description: 问题组件
	 * @prop {type} 问题类型 input输入框 textarea radio单选框 checkbox多选框 select rate slider
	 * @prop {title} 标题
	 * @prop {isMust} 是否必填
	 * @prop {direction} 说明
	 * @prop {
	 *      options  
	 *      {label 显示内容
	 *      img
	 *      isFill 是否填空
	 * 		isMust 是否必填
	 * 		score 分数}
	 * } 选项
	 * @prop {score} 评分时传入的默认值
	 * @prop {minText,maxText} 分数左右的文字
	 * @prop {rateStyle} rate时显示内容 star like num
	 */
	export default {
		name: "question",
		data() {
			return {
				optionsTemp: [],
				checkboxVal: [],
				value: null,
				inputValue: '',
				sliderValue: 0,
				activeScoreStyle: {},
				isPhone: false,
				showDel: false, //是否显示删除按钮
			};
		},
		props: {
			width: {
				required: false
			},
			no: {
				type: Number,
				required: true
			},
			type: {
				type: String,
				required: true
			},
			title: {
				type: String,
				required: true
			},
			direction: {
				type: String,
				required: false
			},
			isMust: {
				type: Boolean,
				default: false
			},
			options: {
				type: Array
			},
			rateStyle: {
				type: String,
				default: 'star'
			},
			score: {
				type: Number,
				default: 0
			},
			minText: {
				type: String,
				default: '不满意'
			},
			maxText: {
				type: String,
				default: '满意'
			},
			min: {
				type: Number,
				default: 1
			},
			max: {
				type: Number,
				default: 100
			},
			disabled: {
				type: Boolean,
				default: false
			},
			val: {

			},
			isEdit: { //是否为编辑
				type: Boolean,
				default: false
			},
			hasMap: { 
				type: Boolean,
				default: false
			}
		},
		watch: {
			type() {
				if (this.type == 'rate' || this.type == 'slider') {
					this.value = 0;
				}
				// if (this.type == 'checkbox') {
				// 	for (let item of this.options) {
				// 		item.checked = false;
				// 		item.checkedFlag = false;
				// 	}
				// }
			},
			value(val) {
				if (this.type == 'input' || this.type == 'textarea') {
					// this.val = val;
					this.$emit("update:val", val);
				}
			},
			optionsTemp(val) {
				this.$emit("update:options", this.optionsTemp);
			},
			options(val) {
				this.$emit("update:options", val);
				this.optionsTemp = this.options;
			},
			sliderValue(val) {
				this.$emit("update:val", val);
			},
			// val(val) {
			// 	if (this.type == 'input' || this.type == 'textarea') {
			// 		this.value = val
			// 	}
			// 	if (this.type == 'slider') {
			// 		this.sliderValue = Number(val);
			// 	}
			// }
		},
		computed: {},
		created() {
			if (this.type == 'rate' || this.type == 'slider') {
				this.value = 0;
			}
			// 编辑下已有映射关系 默认选中第一项
			if (this.hasMap && this.isEdit) {
				this.value = 0;
			}
			this.optionsTemp = this.options;
		},
		mounted() {
			this.isPhone = navigator.userAgent.match(
				/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
			);
			// if (this.type == 'checkbox') {
			// 	for (let item of this.optionsTemp) {
			// 		item.checked = false;
			// 		item.checkedFlag = false;
			// 	}
			// }
			if (this.disabled) {
				if (this.type == 'input' || this.type == 'textarea' || this.type == 'rate') {
					this.value = this.val
				}
				if (this.type == 'slider') {
					this.sliderValue = Number(this.val);
				}
				if (this.type == 'rate' && this.rateStyle == 'num') {
					this.scoreClick(this.val - 1)
				}
				if (this.type == 'select') {
					for (let i = 0; i < this.options.length; i++) {
						let option = this.options[i];
						if (option.checkedFlag) {
							this.value = i;
						}
					}

				}
			}

		},
		methods: {
			selectChange(e) {
				for (let i = 0; i < this.optionsTemp.length; i++) {
					this.optionsTemp[i].checkedFlag = i == e;
				}
			},
			radioChange(e) {
				for (let i = 0; i < this.optionsTemp.length; i++) {
					this.optionsTemp[i].checkedFlag = i == e.target.value;
				}
				// this.optionsTemp[e.target.value].checkedFlag = true;
				// this.$emit("update:options", this.optionsTemp);
			},
			checkboxChange(idx) {
				if (this.optionsTemp[idx].checkedFlag) {
					this.optionsTemp[idx].checkedFlag = false;
					return;
				}
				let i = 0;
				for (let item of this.optionsTemp) {
					if (item.checkedFlag) {
						i++;
					}
				}
				if (this.max != 0) {
					if (i < this.max) {
						this.optionsTemp[idx].checkedFlag = true;
					} else {
						this.$message.warning(`至多${this.max}项`)
					}
				} else {
					this.optionsTemp[idx].checkedFlag = true;
				}
				// for (let item of this.options) {
				// 	if (item.checked) {
				// 		this.checkboxVal.push(item);
				// 	}
				// }
				// this.$emit("update:options", this.optionsTemp);
			},
			scoreClick(idx, value) {
				//先判断是否点击过，是就取消点击
				if (this.value - 1 == idx && !this.disabled) {
					this.value = -1;
					this.activeScoreStyle = {
						backgroundImage: 'linear-gradient(to right,#fff 0,#00B7B3 ' + 0 + '%,#fff ' + 0 +
							'%)'
					}
					for (let i = 0; i < this.optionsTemp.length; i++) {
						this.optionsTemp[i].checkedFlag = false;
					}
					return;
				}

				this.value = idx + 1;
				let percentage = (idx + 1) / this.options.length * 100;
				this.activeScoreStyle = { //激活的分数
					backgroundImage: 'linear-gradient(to right,#fff 0,#00B7B3 ' + percentage + '%,#fff ' +
						percentage +
						'%)'
				}
				for (let i = 0; i < this.optionsTemp.length; i++) {
					this.optionsTemp[i].checkedFlag = i <= idx;
				}
				this.$emit("update:options", this.optionsTemp);
			},
			rateChange(e) {
				let idx = e - 1;
				if (idx >= 0) {
					for (let i = 0; i <= idx; i++) {
						this.optionsTemp[i].checkedFlag = true;
					}
				} else {
					for (let item of this.optionsTemp) {
						item.checkedFlag = false;
					}
				}

				this.$emit("update:options", this.optionsTemp);
			}
		}
	};
</script>

<style lang="less" scoped>
	.question>div {
		display: flex;

		.container {
			flex: 1;
			text-align: left;
		}
	}
	.phone .container {
			width: 75% !important;
		}
	.no {
		// min-width: 30px;
		text-align: right;
		font-size: 16px;
		// font-size: 1rem;
		line-height: 22px;
		// line-height: 1.375rem;
		margin: 15px 0;
		// margin: 0.9375rem 0;
		box-sizing: border-box;
		font-weight: 600;
		display: flex;
		.isMust {
			color: #D9161C;
		}
	}

	.title {
		font-size: 16px;
		// font-size: 1rem;
		line-height: 22px;
		// line-height: 1.375rem;
		color: #262626;
		text-align: left;
		margin: 15px 0;
		// margin: 0.9375rem 0;
		box-sizing: border-box;
		font-weight: 600;
		word-break: break-all;
	}

	.direction {
		font-size: 12px;
		// font-size: 0.75rem;
		font-weight: 400;
		color: #AAAAAA;
		text-align: left;
		margin: -8px 0 15px;
		// margin: -0.5rem 0 0.9375rem;
		word-break: break-all;
	}

	input {
		width: 270px;
		// width: 16.875rem;
		// height: 32px;
		// height: 2rem;
		padding: 5px 8px;
		// padding: 0.3125rem 0.5rem;
		box-sizing: border-box;
		border-radius: 4px;
		// border-radius: 0.25rem;
		border: 1px solid #EEEEEE;
		display: block;
	}

	input:focus，textarea:focus {
		outline: 1px solid #58b195 !important;
		border: 1px solid #58b195 !important;
		outline-color: #58b195;
	}
	.select {
		padding-bottom: 1rem;
		/deep/.ant-select {
			
			border-image: initial;
			border-radius: 0.25rem;
			 border: none;
			.ant-select-selection {
				// border: none;
				border-radius: 0.25rem;
				border-color: rgb(238, 238, 238) !important;
			}
		}
	}
	.input,
	.textarea {
		display: flex;
		// font-size: 1rem;
		font-size: 16px;
		
		.container {
			// flex: 1;
			padding-bottom: 24px;
			// padding-bottom: 1.5rem;
			box-sizing: border-box;

			input,
			textarea {
				// margin-left: 24px;
				width: 300px;
				// flex: 1;
				// width: 16.875rem;
				height: 32px;
				// height: 2rem;
				padding: 5px 8px;
				padding: 0.3125rem 0.5rem;
				box-sizing: border-box;
				border-radius: 4px;
				// border-radius: 0.25rem;
				border: 1px solid #EEEEEE;
				display: block;
				color: #777;
				// font-size: 16px;
				
			}

			textarea {
				height: 100px;
				// height: 6.25rem;
			}
		}
	}
	/deep/.ant-input {
		width: 300px !important;
	}

	.radio {
		display: flex;

		.radioGroup {
			display: flex;
		}

		.radioItem {
			display: flex;
			align-items: center;
			margin-bottom: 15px;
			// margin-bottom: 15px;

			.img {
				// padding-left: 20px;
				padding-left: 1.25rem;

				/deep/.ant-radio {
					position: absolute !important;
					// top: 40px;
					top: 2.5rem;
					left: 0px;
				}
			}

			.ant-radio-wrapper {
				margin-right: 0;
				color: #777;
				font-size: 16px;
				// font-size: 1rem;
				display: flex;
				align-items: center;


				.radioCon {
					display: flex;
					flex-direction: column;
					justify-content: flex-start;
					align-items: flex-start;
					flex-wrap: wrap;
					font-size: 14px;
					&>span {
						display: block;
						width: 96px;
						// width: 6rem;
						text-align: center;
					}

					.imgCon {
						width: 96px;
						// width: 6rem;
						height: 96px;
						// height: 6rem;
						border-radius: 5px;
						// border-radius: 0.3125rem;
						position: relative;
						.image {
							width: 100%;
							height: 100%;
						}
						.del {
							position: absolute;
							width: 100%;
							height: 100%;
							background:rgba(0,0,0,0.2);
							top: 0;
							left: 0;
							display: flex;
							justify-content: center;
							align-items: center;
						}
					}
				}

				.radioCon2 {
					display: flex;
					align-items: center;
					flex-wrap: wrap;
					font-size: 14px;
					&>span {
						margin-right: 10px;
					}
				}

				div.input {
					display: flex;
					align-items: center;
					border-bottom: 1px solid #eee;

					span.red {
						color: #D9161C;
						font-weight: 600;
					}

					input {
						border: none;
						outline: none;
					}
				}

			}

		}
	}

	.checkbox {
		.title span {
			font-size: 16px;
			// font-size: 1rem;
			color: #AAAAAA;
		}

		.checkboGroup {
			display: flex;
			flex-direction: column;
			justify-content: flex-start;

			&>div {
				display: flex;
				margin-bottom: 15px;
				// margin-bottom: 0.9375rem;
				color: #777;
				font-size: 16px;
				// font-size: 1rem;
			}

			.ant-checkbox-wrapper {
				display: flex;
				align-items: center;
			}

			.img {
				padding-left: 20px;
				// padding-left: 1.25rem;
				position: relative;

				/deep/.ant-checkbox {
					position: absolute !important;
					top: 40px;
					// top: 2.5rem;
					left: 0px;
				}
			}

			.checkboxCon {
				display: flex;
				flex-direction: column;
				justify-content: flex-start;
				align-items: flex-start;
				font-size: 14px;
				&>span {
					display: block;
					width: 96px;
					// width: 6rem;
					text-align: center;
				}

				.imgCon {
					width: 96px;
					// width: 6rem;
					height: 96px;
					// height: 6rem;
					border-radius: 5px;
					// border-radius: 0.3125rem;
					position: relative;
					.image {
						width: 100%;
						height: 100%;
					}
					.del {
						position: absolute;
						width: 100%;
						height: 100%;
						background:rgba(0,0,0,0.2);
						top: 0;
						left: 0;
						display: flex;
						justify-content: center;
						align-items: center;
					}
				}

			}

			.checkboxCon2 {
				display: flex;
				align-items: center;
				flex-wrap: wrap;

				&>span {
					margin-right: 10px;
				}
			}

			div.input {
				display: flex;
				align-items: center;
				border-bottom: 1px solid #eee;
				font-size: 14px;
				span.red {
					color: #D9161C;
					font-weight: 600;
				}

				input {
					border: none;
					outline: none;
				}
			}

		}
	}

	.rate {
		.score {
			color: #777;
			font-size: 16px;
			// font-size: 1rem;

			.minText {
				margin-right: 10px;
				// margin-right: 0.625rem;
			}

			.maxText {
				margin-left: 10px;
				// margin-left: 0.625rem;
				color: #00AAA6;
			}

			.ant-rate {
				color: #00AAA6;
			}
		}

		.scoreCon {
			display: flex;
			align-items: center;
			flex-wrap: wrap;
			color: #777;
			font-size: 1rem;

			.minText {
				margin-right: 10px;
				// margin-right: 0.625rem;
			}

			.maxText {
				// margin-left: 10px;
				color: #00AAA6;
			}

			.rate {
				display: flex;
				align-items: center;
				justify-content: space-between;
				color: #777777;
				height: 24px;
				// height: 1.5rem;
				border: 1px solid #EEEEEE;
				border-radius: 2px;
				background: #fff;
				margin-right: 0.625rem;

				div {
					font-size: 14px;
					// font-size: 0.875rem;
					line-height: 24px;
					// line-height: 1.5rem;
					width: 24px;
					// width: 1.5rem;
					height: 24px;
					// height: 1.5rem;
					cursor: pointer;
					text-align: center;
				}

				div.select {
					color: #262626;
				}
			}
		}
	}

	.score {
		.scoreCon {
			display: flex;
			align-items: center;

			.minText {
				margin-right: 10px;
				// margin-right: 0.625rem;
			}

			.maxText {
				margin-left: 10px;
				margin-left: 0.625rem;
				color: #00AAA6;
			}

			.rate {
				display: flex;
				align-items: center;
				justify-content: space-between;
				color: #777777;
				width: 285px;
				// width: 17.8125rem;
				height: 24px;
				// height: 1.5rem;
				border: 1px solid #EEEEEE;
				border-radius: 2px;
				background: #fff;

				div {
					font-size: 14px;
					// font-size: 0.875rem;
					line-height: 24px;
					// line-height: 1.5rem;
					width: 24px;
					// width: 1.5rem;
					height: 24px;
					// height: 1.5rem;
					cursor: pointer;
				}

				div.select {
					color: #262626;
				}
			}
		}
	}

	.slider {
		.slideCon {
			width: 300px;
			// width: 18.75rem;

			&>div.text {
				display: flex;
				justify-content: space-between;
				align-items: center;

				.maxText {
					color: #00AAA6;
				}
			}

			.ant-slider {
				width: 270px;
				// width: 16.875rem;
				margin: 10px auto 0;
				// margin: 0.625rem auto 0;
			}
		}

	}

	/deep/.ant-radio-disabled.ant-radio-checked {
		.ant-radio-inner {
			border-color: #00AAA6 !important;
			background: transparent !important;
		}

		.ant-radio-inner:after {
			background: #00AAA6;
		}
	}

	/deep/.ant-checkbox-disabled.ant-checkbox-checked {
		.ant-checkbox-inner {
			border-color: #00AAA6 !important;
			background: #00AAA6;
		}

		.ant-checkbox-inner:after {
			border-color: #fff;
		}
	}

	/deep/.ant-slider-disabled {
		.ant-slider-track {
			background-color: #00AAA6 !important;
		}

		.ant-slider-handle {
			border: solid 2px #64d1c4 !important;
		}
	}

	.phone {
		.rate {
			.score {
				display: flex;
				align-items: center;
				flex-wrap: wrap;
			}

		}
	}
</style>
